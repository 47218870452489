import React from 'react';
import './SpotPopup.css';

const SpotPopup = ({ isOpen, onClose, spot, Key, setKey, verificationMessage, verifyProximity, verifyKey }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="activity-info">
            {spot.spotActivities.map((activity, index) => (
              <div key={index} className="activity-details">
                <div className="icon-circle">
                  <img src={`/activity/${activity.activityTemplate.iconName}`} alt="icon" />
                </div>
                <h4>{activity.activityTemplate.name} ({activity.activityLevelTemplate.goal})</h4>
              </div>
            ))}
          </div>
          <div className="spot-name">
            <h3>{spot.name}</h3>
          </div>
        </div>
        <div className="modal-body">
          {spot.spotActivities.map((activity, index) => (
            <div key={index}>
              <p className="description">{activity.activityTemplate.description}</p>
              <p className="level-note">{activity.activityLevelTemplate.levelNote}</p>
            </div>
          ))}
          <button onClick={verifyProximity} className="styled-button">Enter</button>
          {verificationMessage && <p className="upload-message">{verificationMessage}</p>}
          <input
            type="text"
            value={Key}
            onChange={(e) => setKey(e.target.value)}
            placeholder="Enter spot key"
            className="styled-input"
          />
          <button onClick={verifyKey} className="styled-button">Verify</button>
        </div>
        <button onClick={onClose} className="styled-button close-button">Close</button>
      </div>
    </div>
  );
};

export default SpotPopup;
