import React from 'react';


import CardMenuButton from '../Planet/CardMenuButton';

import './Market.css';
import { useNavigate } from 'react-router-dom';

const Market = () => {
 

  const navigate = useNavigate();


  
  return (
    <div className="containerMarket">
      <div className="menu">
        <CardMenuButton title="Market" onClick={() => navigate("/g/market")} />
        <CardMenuButton title="Learn" onClick={() => window.location.href = '/g/spell'} />
        <CardMenuButton title="Verifications" onClick={() => navigate("/g/verifications")} />
      </div>
    

      <h2>containerMarket</h2>
      
    </div>
  );
};

export default Market;
