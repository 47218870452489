import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SpellToLearn.css'; // Create and import a CSS file for styling
import SpellCard from './SpellCard';
import CardMenuButton from '../Planet/CardMenuButton';

import {  useNavigate } from 'react-router-dom';


const SpellsToLearn = () => {
  const [spells, setSpells] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchSpells = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/spell/spellToLearn', { headers });
        console.log(response.data);
        setSpells(response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error fetching spells:', error);
        setError(error.message);
        }
      }
    };

    fetchSpells();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="spells-to-learn-container">
          <div className="menu">
        <CardMenuButton title="Spells" onClick={() => navigate('/g/learnedSpells')} />
        <CardMenuButton title="Learn" onClick={() => navigate('/g/spellToLearn')}  />
        <CardMenuButton title="Stack" onClick={() => console.log('Stack Spells')} />
      </div>
      <h2>Spells To Learn</h2>
      <div className="spell-cards-container">
        {spells.map((spell) => (
          <SpellCard key={spell.id} spell={spell} />
        ))}
      </div>
    </div>
  );
};

export default SpellsToLearn;
