import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login'; // Your login component

import Register from './Register';

import Layout from './Layout';





import SpellType from './Spell/SpellType';
import SpellTemplate from './Spell/SpellTemplate';
import SpellLevelTemplate from './Spell/SpellLevelTemplate';
import SpellLevelTemplateDetails from './Spell/SpellLevelTemplateDetail';

import ItemTemplates from './Item/ItemTemplate';


import CharacterAttributeLevel from './Character/CharacterAttributeLevel';
import CharacterItems from './Character/CharacterItems';
import CharacterItemCraft from './Character/CharacterItemCraft';

import VideoWithBeep from './Spell/VideoWithBeep';
import MapComponent from './Activity/MapSpot';
import CharacterSpells from './Spell/CharacterSpell';
import CombatScreen from './Combat/CombatScreen';

import VerificationRequestsScreen from './Market/VerificationRequestsScreen';
import SpellToLearn from './Spell/SpellToLearn';
import SpellLearned from './Spell/SpellLearned';


import CombatKeys from './Combat/CombatKeys';

import Market from './Market/Market';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/g" element={<Layout />}>

        



          {/*Character */}
          <Route path="character" element={<CharacterAttributeLevel />} />
          <Route path="characterItems" element={<CharacterItems />} />
          <Route path="characterItemCraft" element = {<CharacterItemCraft />} />


          <Route path="itemTemplates" element={<ItemTemplates />} />

 


        

          {/*Map */}
          <Route path="mapSpots" element={<MapComponent />} />
         

           {/*Combat */}
          <Route path="combatKeys" element = {<CombatKeys /> } />
          <Route path="combat/:spotId/:combatId"  element={<CombatScreen />} />


          {/* Spell */}
          <Route path="CharacterSpell" element={<CharacterSpells />} />
          <Route path="spellToLearn" element = {<SpellToLearn />} />
          <Route path="learnedSpells" element = {<SpellLearned />} />
          <Route path="video/:videoId" element={<VideoWithBeep />} />

          <Route path="SpellType" element={<SpellType />} />
          <Route path="SpellTemplate" element={<SpellTemplate />} />
          <Route path="SpellTemplate/:id" element={<SpellLevelTemplate />} />
          <Route path="spellTemplate/:spellTemplateId/SpellLevelTemplate/:spellLevelTemplateId" element={<SpellLevelTemplateDetails />} />

          {/*Market */}
          <Route path="market" element = {<Market />} />
          <Route path="verifications" element={<VerificationRequestsScreen/>} />

     
        </Route>
        <Route path="video/:videoId" element={<VideoWithBeep />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="mapSpotsTest" element={<MapComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
