import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VerificationRequestsScreen.css';
import CardMenuButton from '../Planet/CardMenuButton';
import VerificationDetailModal from './VerificationDetailModal'; // New modal component
import CreateVerdictModal from './CreateVerdictModal'; // New modal component

import { useNavigate } from 'react-router-dom';

const VerificationRequestsScreen = () => {
  const [spotVerificationRequests, setSpotVerificationRequests] = useState([]);
  const [spellVerificationRequests, setSpellVerificationRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isVerdictModalOpen, setIsVerdictModalOpen] = useState(false);
  const [error, setError] = useState("");


  const navigate = useNavigate();


  useEffect(() => {
    const fetchVerificationRequests = async () => {
      try {
        const token = localStorage.getItem('token');

        console.log(error);
        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/verificationRequests', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
console.log("requests", response.data);
        const requests = response.data.$values || response.data.result;
  // Split requests into spot and spell verifications
  const spotRequests = requests.filter(request =>/* request.hasOwnProperty('spotId') && */request?.spotId !== null);
  const spellRequests = requests.filter(request => /*request.hasOwnProperty('spellId') && */request?.spellId !== null);


        setSpotVerificationRequests(spotRequests);
        setSpellVerificationRequests(spellRequests);
      } catch (error) {
        console.error('Error fetching verification requests:', error);
      }
    };

    fetchVerificationRequests();
  }, []);

  const handleRequestClick = (request) => {
    setSelectedRequest(request);
    setIsDetailModalOpen(true);
  };

  const handleFetchRandomVerification = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/getRandomVerification', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      console.log("response.data.result", response.data.result);
      setSelectedRequest(response.data.result);
      setIsVerdictModalOpen(true);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error fetching spells:', error);
      setError(error.message);
      }
    }
  };

  const handleCreateVerdict = async (verdict) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createVerdict', verdict, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      setIsVerdictModalOpen(false);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error fetching spells:', error);
      setError(error.message);
      }
    }
  };

  return (
    <div className="containerVerificationRequests">
      <div className="menu">
        <CardMenuButton title="Spells" onClick={() => navigate("/g/")} />
        <CardMenuButton title="Learn" onClick={() => window.location.href = '/g/spell'} />
        <CardMenuButton title="Verifications" onClick={() => navigate("/g/verifications")} />
      </div>
      <button onClick={handleFetchRandomVerification}>Fetch Random Verification</button>

      <h2>Spot Verification Requests</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Spot Name</th>
            <th>Zone Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {spotVerificationRequests.map((request) => (
            <tr key={request.id}>
              <td>{request?.spot?.name}</td>
              <td>{request?.spot?.zone?.name}</td>
              <td>
                <button onClick={() => handleRequestClick(request)}>View Details</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Spell Verification Requests</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Spell Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {spellVerificationRequests.map((request) => (
            <tr key={request.id}>
              <td>{request?.spell?.spellTemplate?.name}</td>
              <td>
                <button onClick={() => handleRequestClick(request)}>View Details</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRequest && (
        <VerificationDetailModal
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailModalOpen(false)}
          request={selectedRequest}
        />
      )}

      {selectedRequest && (
        <CreateVerdictModal
          isOpen={isVerdictModalOpen}
          onClose={() => setIsVerdictModalOpen(false)}
          request={selectedRequest}
          onCreateVerdict={handleCreateVerdict}
        />
      )}
    </div>
  );
};

export default VerificationRequestsScreen;
