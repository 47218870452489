import React, { useEffect, useState } from 'react';
import './VerificationDetailModal.css';
import SignatureIcons from '../Activity/SignatureIcons';

const VerificationDetailModal = ({ isOpen, onClose, request }) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoType, setVideoType] = useState(null);

  useEffect(() => {
    if (request) {
      const videoFile = request.verificationFiles?.find(file => file.contentType.startsWith('video'));
      if (videoFile) {
        setVideoUrl(`/UploadedVideos/${videoFile.name}`);
        setVideoType(videoFile.contentType); // Set the video type dynamically
      }
    }
  }, [request]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Verification Request Details</h3>
        {request ? (
          <>
            <p className="spot-name">{request.spot?.name || 'No Spot Name'}</p>
            <p className="spot-description">{request.spot?.description || 'No Spot Description'}</p>
            <p><strong>Game Attribute ID:</strong> {request.spot?.gameAttributeId || 'No Game Attribute ID'}</p>
            <p><strong>Spot Activities:</strong></p>
            <ul>
              {request.spot?.spotActivities ? (
                request.spot.spotActivities.map(activity => (
                  <li key={activity.id}>Activity ID: {activity.activityTemplateId}, Level: {activity.activityLevelTemplateId}</li>
                ))
              ) : (
                <li>No Spot Activities</li>
              )}
            </ul>
            <p><strong>Created At:</strong> {new Date(request.createdAt).toLocaleString()}</p>
            <p><strong>Text:</strong> {request.text}</p>
            <p><strong>Url:</strong> {request.url}</p>
            <p><strong>Signature:</strong> <SignatureIcons signatureString={request.signatureString} /></p>
            {videoUrl && (
              <div className="video">
                <video controls>
                  <source src={videoUrl} type={videoType} />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </>
        ) : (
          <p>No data available</p>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default VerificationDetailModal;
