import React, { useState } from 'react';
import './register.css'; // Make sure this is the correct path

const Register = () => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    registerKey: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if passwords match
    if (userDetails.password !== userDetails.confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    try {
      const response = await fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/Register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: userDetails.email,
          Password: userDetails.password,
          RegisterKey: userDetails.registerKey
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Registration successful:', data);
        window.location.href = '/login';
      } else {
        console.error('Registration failed:', data.message);
        alert(data.message); // Show the error message from the server
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('An error occurred while trying to register. Please try again.'); // General error message for the user
    }
  };

  return (
    <div className="backgroundRegister">
      <div className="login-screen-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Register</h2>
          {/* Rest of the form */}
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={userDetails.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={userDetails.confirmPassword}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="registerKey">Confirm Password</label>
            <input
              type="test"
              id="registerKey"
              name="registerKey"
              value={userDetails.registerKey}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="login-button">Register</button>
          <p className="register-prompt">
            Already have an account? <a href="/login" className="register-link">Log in</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
