import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CharacterItems.css'; // Ensure you have the correct path to your CSS file

const CharacterChests = () => {
    const [chests, setChests] = useState([]);

    useEffect(() => {
        fetchCharacterChests();
    }, []);

    const fetchCharacterChests = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                window.location.href = '/login';
                throw new Error('No token found');
            }

            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/item/GetCharacterChests', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });


            
console.log("response chest", response.data);
            setChests(response.data);
        } catch (error) {
            console.error('Error fetching chests:', error);
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            }
        }
    };

    const renderChests = (chests) => {
        return chests.map((chest) => (
            <tr key={chest.chestTypeId}>
                <td>{chest?.chestTypeName}</td>
                <td>{chest?.level}</td>
                <td>{chest?.count}</td>
            </tr>
        ));
    };

    return (
<>
            <h2>Chests</h2>
            <div className="table-container">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Chest Type</th>
                            <th>Level</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderChests(chests)}
                    </tbody>
                </table>
            </div>
            </>
    );
};

export default CharacterChests;
