// TargetTypeIcon.js
import React from 'react';
import './SpellTargetTypeIcon.css';

const TargetTypeIcon = ({ id }) => {
  const getIcon = (id) => {
    switch(id) {
      case 1:
        return <i className="mdi mdi-account"></i>; // Self icon
      case 2:
        return <i className="mdi mdi-sword"></i>; // Enemy icon
      case 3:
        return <i className="mdi mdi-account-multiple"></i>; // Ally icon
      case 4:
        return <i className="mdi mdi-hand-heart"></i>; // Anyone icon
      case 5:
        return <i className="mdi mdi-cloud-off-outline"></i>; // No target icon
      default:
        return <i className="mdi mdi-help-circle-outline"></i>; // Default icon
    }
  };

  return (
    <span className="target-type-icon">
      {getIcon(id)}
    </span>
  );
};

export default TargetTypeIcon;
