import React from 'react';
import './SignatureIcons.css';

const SignatureIcons = ({ signatureString }) => {
  const iconsBasePath = '/gestures/';

  // Split the signature string and map each number to the corresponding icon
  const iconElements = signatureString.split('-').map((num, index) => (
    <img key={index} src={`${iconsBasePath}${num}.png`} alt={`Gesture ${num}`} className="gesture-icon" />
  ));

  return <div className="signature-icons">{iconElements}</div>;
};

export default SignatureIcons;