import React  from 'react';

import './CharacterItemCraft.css'; // Ensure you have the correct path to your CSS file

import { useNavigate } from 'react-router-dom';

import CardMenuButton from '../Planet/CardMenuButton';

const CharacterItemCraft = () => {
    
const navigate = useNavigate();


    return (
<div className='containerCharacterItemCraft'>

<div className="menu">
        <CardMenuButton title="Postava" onClick={() => navigate('/g/character')} />
        <CardMenuButton title="Inventár" onClick={() => navigate('/g/characterItems')} />
        <CardMenuButton title="Dielňa" onClick={() => navigate('/g/characterItemCraft')} />
      </div>
            <h1>Character Item Crafts</h1>
           
      </div>
    );
};

export default CharacterItemCraft;
