import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import axios from 'axios';
import beepSound from './beep.mp3'; // Ensure you have a beep sound file
import './VideoWithBeep.css'; // Ensure you have the correct path to your CSS file

const YouTubeWithInteractions = () => {
  const { videoId } = useParams();
  const [videoDetails, setVideoDetails] = useState(null);
  const [isBeeped, setIsBeeped] = useState(false);
  const [clickedAfterPrompt, setClickedAfterPrompt] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [time, setTime] = useState(0);
  const [times, setTimes] = useState([]);
  const [totalWatchTime, setTotalWatchTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const promptInterval = useRef(null);
  const beepAudio = useRef(new Audio(beepSound));
  const playerRef = useRef(null);
  const timerInterval = useRef(null);
  const watchTimeInterval = useRef(null);

  useEffect(() => {
    fetchVideoDetails();
    return () => {
      clearInterval(promptInterval.current);
      clearInterval(timerInterval.current);
      clearInterval(watchTimeInterval.current);
    };
  }, [videoId]);

  const fetchVideoDetails = async () => {
    try {
      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellVideo/${videoId}`);
      setVideoDetails(response.data);
    } catch (error) {
      console.error('Error fetching video details:', error);
    }
  };

  const startTimer = () => {
    setTime(0);
    timerInterval.current = setInterval(() => {
      setTime(prev => prev + 10);
    }, 10);
  };

  const stopTimer = () => {
    clearInterval(timerInterval.current);
    timerInterval.current = null;
  };

  const startWatchTime = () => {
    watchTimeInterval.current = setInterval(() => {
      const currentTime = playerRef.current.getCurrentTime();
      setTotalWatchTime(prev => prev + 1);

      const upcomingQuestion = videoDetails.spellVideoQuestions.find(q => Math.floor(currentTime) === q.secondsFromStart);
      if (upcomingQuestion) {
        setCurrentQuestion(upcomingQuestion);
        setShowQuestion(true);
        playerRef.current.pauseVideo();
      }
    }, 1000);
  };

  const stopWatchTime = () => {
    clearInterval(watchTimeInterval.current);
    watchTimeInterval.current = null;
  };

  const handlePlay = () => {
    setIsPlaying(true);
    startWatchTime();
    if (!promptInterval.current) {
      promptInterval.current = setInterval(() => {
        if (Math.random() < 0.2) { // Adjust the probability as needed
          beepAudio.current.play();
          setIsBeeped(true);
          setClickedAfterPrompt(false);
          startTimer();
        }
      }, 10000); // Try to prompt every 10 seconds
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    stopWatchTime();
    clearInterval(promptInterval.current);
    promptInterval.current = null;
    stopTimer();
  };

  const handleEnd = () => {
    setIsPlaying(false);
    stopWatchTime();
    clearInterval(promptInterval.current);
    promptInterval.current = null;
    setVideoWatched(true);
    stopTimer();
  };

  const handleButtonClick = () => {
    if (isBeeped) {
      stopTimer();
      setTimes(prevTimes => [...prevTimes, { prompt: `Prompt ${prevTimes.length + 1}`, time }]);
      setClickedAfterPrompt(true);
      setIsBeeped(false);
    }
  };

  const handleQuestionAnswered = () => {
    setShowQuestion(false);
    setCurrentQuestion(null);
    playerRef.current.playVideo();
  };

  const formatTime = (time) => {
    const milliseconds = time % 1000;
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}.${milliseconds}`;
  };

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };
console.log(isPlaying);
  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onStateChange = (event) => {
    switch (event.data) {
      case YouTube.PlayerState.PLAYING:
        handlePlay();
        break;
      case YouTube.PlayerState.PAUSED:
      case YouTube.PlayerState.BUFFERING:
        handlePause();
        break;
      case YouTube.PlayerState.ENDED:
        handleEnd();
        break;
      default:
        break;
    }
  };

  if (!videoDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="youtube-container">
      <YouTube
        videoId={videoDetails.videoId}
        opts={opts}
        onReady={onReady}
        onStateChange={onStateChange}
        className="youtube-player"
      />
      {isBeeped && (
        <button className="beep-button" onClick={handleButtonClick}>
          Click me after beep
        </button>
      )}
      {showQuestion && currentQuestion && (
        <div className="question-overlay">
          <p>{currentQuestion.questionTitle}</p>
          <button onClick={handleQuestionAnswered}>{currentQuestion.answer1}</button>
          <button onClick={handleQuestionAnswered}>{currentQuestion.answer2}</button>
          <button onClick={handleQuestionAnswered}>{currentQuestion.answer3}</button>
          <button onClick={handleQuestionAnswered}>{currentQuestion.answer4}</button>
        </div>
      )}
      {clickedAfterPrompt && <p>Thank you for clicking!</p>}
      {videoWatched && <p>You have watched the full video!</p>}
      <p>Total watch time: {formatTime(totalWatchTime * 1000)}</p>
      <div>
        {times.map((record, index) => (
          <p key={index}>{record.prompt}: {formatTime(record.time)} seconds</p>
        ))}
      </div>
    </div>
  );
};

export default YouTubeWithInteractions;
