import React, { useState, useEffect } from 'react';
import './CreateVerdictModal.css';
import SignatureIcons from '../Activity/SignatureIcons';
import VerdictSlider from '../Activity/VerdictSlider'; // Ensure the path is correct

const CreateVerdictModal = ({ isOpen, onClose, request, onCreateVerdict }) => {
  const [verdict, setVerdict] = useState({ verdict: 0, text: '', VerificationRequestId: request?.id });

  useEffect(() => {
    if (request) {
      setVerdict((prevVerdict) => ({
        ...prevVerdict,
        VerificationRequestId: request.id,
      }));
    }
  }, [request]);

  const handleVerdictChange = (value) => {
    setVerdict((prevVerdict) => ({
      ...prevVerdict,
      verdict: value,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setVerdict((prevVerdict) => ({
      ...prevVerdict,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreateVerdict(verdict);
  };

  if (!isOpen) return null;

  const videoFile = request?.verificationFiles?.find(file => file.contentType.startsWith('video'));
  const videoUrl = videoFile ? `https://dakujemorg-002-site1.ftempurl.com/videos/${videoFile.name}` : null;
  const videoType = videoFile ? videoFile.contentType : 'video/mp4';

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Create Verdict</h3>
        <div>
          <p className="spot-name">{request.spot?.name || request.spell?.spellTemplate?.name || 'No Spot Name'}</p>
          <p className="spot-description">{request.spot?.description || request.spell?.spellLevelTemplate?.activityLevelTemplate?.levelNote || 'No Spot Description'}</p>
          <p><strong>Activities:</strong></p>
          <ul>
            {request.spot?.spotActivities ? (
              request.spot.spotActivities.map(activity => (
                <li key={activity.id}>Activity: {activity.activityLevelTemplate?.levelNote}</li>
              ))
            ) : (
              <li>No Spot Activities</li>
            )}
          </ul>
          {request.spell?.activityLevelTemplate &&
            <div key={request.spell?.activityLevelTemplateId}>Activity: {request.spell.activityLevelTemplate?.levelNote}</div>
          }
          <p><strong>Created At:</strong> {new Date(request.createdAt).toLocaleString()}</p>
          <p><strong>Signature String:</strong> <SignatureIcons signatureString={request.signatureString} /></p>
          <p><strong>URL:</strong> <a href={request.url} target="_blank" rel="noopener noreferrer">{request.url}</a></p>
          <p><strong>Text:</strong> {request.text}</p>
          {videoUrl && (
            <div className="video">
              <video controls>
                <source src={videoUrl} type={videoType} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Verdict:</label>
            <VerdictSlider value={verdict.verdict} onChange={handleVerdictChange} />
          </div>
          <div>
            <label>Text:</label>
            <textarea name="text" value={verdict.text} onChange={handleTextChange} className="styled-input"></textarea>
          </div>
          <button type="submit" className="styled-button">Submit Verdict</button>
        </form>
        <button onClick={onClose} className="styled-button close-button">Close</button>
      </div>
    </div>
  );
};

export default CreateVerdictModal;
