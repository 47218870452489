import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ItemTemplates = () => {
    const [itemTemplates, setItemTemplates] = useState([]);
    const [itemWearTypes, setItemWearTypes] = useState([]);
    const [newItemTemplate, setNewItemTemplate] = useState({
        name: '',
        description: '',
        itemWearTypeId: '',
        iconName: ''
    });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchItemTemplates();
        fetchItemWearTypes();
    }, []);

    const fetchItemTemplates = async () => {
        try {
            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/item/itemtemplates');
            console.log("response", response.data);
            setItemTemplates(response.data.$values || []);
        } catch (error) {
            console.error('Error fetching item templates:', error);
        }
    };

    const fetchItemWearTypes = async () => {
        try {
            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/item/itemWearTypes');
            console.log("itemWearTypes", itemWearTypes);
            setItemWearTypes(response.data.$values || []);
        } catch (error) {
            console.error('Error fetching item wear types:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewItemTemplate({ ...newItemTemplate, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/item/itemtemplates', newItemTemplate);
            fetchItemTemplates(); // Refresh the list
            setShowModal(false); // Close the modal
        } catch (error) {
            console.error('Error creating item template:', error);
        }
    };

    return (
        <div className="container">
            <h1>Item Templates</h1>
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>Add Item Template</button>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Item Wear Type</th>
                        <th>Icon</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {itemTemplates.map((template) => (
                        <tr key={template.id}>
                            <td>{template.name}</td>
                            <td>{template.description}</td>
                            <td>{template.itemWearType?.name || "Unknown"}</td>
                            <td>{template.iconName}</td>
                            <td>
                                <Link to={`/itemTemplate/${template.id}`} className="btn btn-info">
                                    View Details
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Item Template</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={newItemTemplate.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            name="description"
                                            value={newItemTemplate.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Item Wear Type</label>
                                        <select
                                            className="form-control"
                                            name="itemWearTypeId"
                                            value={newItemTemplate.itemWearTypeId}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select Item Wear Type</option>
                                            {itemWearTypes.map((type) => (
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Icon Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="iconName"
                                            value={newItemTemplate.iconName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Add</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ItemTemplates;
