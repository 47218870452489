import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CharacterItems.css'; // Ensure you have the correct path to your CSS file

import { useNavigate } from 'react-router-dom';

import CardMenuButton from '../Planet/CardMenuButton';
import CharacterChests from './CharacterChests';

const CharacterItems = () => {
    const [wornItems, setWornItems] = useState([]);
    const [notWornItems, setNotWornItems] = useState([]);
    const [error, setError] = useState(null); // Add error state

    const navigate = useNavigate(); 

    useEffect(() => {
        fetchCharacterItems();
    }, []);

    const fetchCharacterItems = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                window.location.href = '/login';
                throw new Error('No token found');
            }

            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/item/CharacterItems', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            const items = response.data;
            console.log("items", items);
            setWornItems(items.filter(item => item.isWeared));
            setNotWornItems(items.filter(item => !item.isWeared));
        } catch (error) {
            console.log("error", error);
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            } else {
                console.error('Error fetching items:', error);
                setError(error.message);
            }
        }
    };

    const handleWearItem = async (itemId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`https://dakujemorg-002-site1.ftempurl.com/api/item/WearItem/${itemId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            fetchCharacterItems();
        } catch (error) {
            console.error('Error wearing item:', error);
        }
    };

    const handleUnwearItem = async (itemId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`https://dakujemorg-002-site1.ftempurl.com/api/item/UnwearItem/${itemId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            fetchCharacterItems();
        } catch (error) {
            console.error('Error unwearing item:', error);
        }
    };

    const renderItems = (items, isWorn) => {
        return items.map(item => (
            <tr key={item.id}>
                <td>{item.itemTemplate?.name}</td>
                <td>{item.itemTemplate?.itemWearType?.name}</td>
                <td>{item.itemLevelTemplate?.level}</td>
                <td>{item.itemTemplate?.itemRarityType?.name}</td>
                <td>{item.damage}</td>
                <td>
                    {isWorn ? (
                        <div className='buttonA' onClick={() => handleUnwearItem(item.id)}>Unwear It</div>
                    ) : (
                        <div className='buttonA' onClick={() => handleWearItem(item.id)}>Wear It</div>
                    )}
                </td>
            </tr>
        ));
    };

    return (
        <div className='containerCharacterItems'>
            <div className="menu">
                <CardMenuButton title="Postava" onClick={() => navigate('/g/character')} />
                <CardMenuButton title="Inventár" onClick={() => navigate('/g/characterItems')} />
                <CardMenuButton title="Dielňa" onClick={() => navigate('/g/characterItemCraft')} />
            </div>
            <h1>Character Items</h1>
            {error && <p className="error">{error}</p>} {/* Display error message if exists */}
            <h2>Worn Items</h2>
            <div className="table-container">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Wear Type</th>
                            <th>Level</th>
                            <th>Rarity</th>
                            <th>Damage</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems(wornItems, true)}
                    </tbody>
                </table>
            </div>
            <h2>Not Worn Items</h2>
            <div className="table-container">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Wear Type</th>
                            <th>Level</th>
                            <th>Rarity</th>
                            <th>Damage</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderItems(notWornItems, false)}
                    </tbody>
                </table>
            </div>
            <CharacterChests />
        </div>
    );
};

export default CharacterItems;
