import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const SpellTemplates = () => {
    const [spellTemplates, setSpellTemplates] = useState([]);
    const [spellTypes, setSpellTypes] = useState([]);
    const [newSpellTemplate, setNewSpellTemplate] = useState({
        name: '',
        description: '',
        spellTypeId: ''
    });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchSpellTemplates();
        fetchSpellTypes();
    }, []);

    const fetchSpellTemplates = async () => {
        try {
            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/spell/spelltemplates');
            console.log("response", response.data);
            setSpellTemplates(response.data.$values || []);
        } catch (error) {
            console.error('Error fetching spell templates:', error);
        }
    };

    const fetchSpellTypes = async () => {
        try {
            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/spell/spelltypes');
            setSpellTypes(response.data.$values || []);
        } catch (error) {
            console.error('Error fetching spell types:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSpellTemplate({ ...newSpellTemplate, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/spell/spelltemplates', newSpellTemplate);
            fetchSpellTemplates(); // Refresh the list
            setShowModal(false); // Close the modal
        } catch (error) {
            console.error('Error creating spell template:', error);
        }
    };

    return (
        <div className="container">
            <h1>Spell Templates</h1>
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>Add Spell Template</button>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Spell Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {spellTemplates.map((template) => (
                        <tr key={template.id}>
                            <td>{template.name}</td>
                            <td>{template.description}</td>
                            <td>{template.spellType?.name || "Unknown"}</td>
                            <td>
                                <Link to={`/spellTemplate/${template.id}`} className="btn btn-info">
                                    View Details
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Spell Template</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={newSpellTemplate.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            name="description"
                                            value={newSpellTemplate.description}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Spell Type</label>
                                        <select
                                            className="form-control"
                                            name="spellTypeId"
                                            value={newSpellTemplate.spellTypeId}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select Spell Type</option>
                                            {spellTypes.map((type) => (
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Add</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpellTemplates;
