import React from 'react';
import './SpellImprovementTypeIcon.css';

const ImprovementTypeIcon = ({ type }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'HpRegen':
        return <i className="mdi mdi-heart-pulse"></i>;
      case 'LifeSteal':
        return <i className="mdi mdi-heart"></i>;
      case 'LifeStealMagic':
        return <i className="mdi mdi-magic"></i>;
      case 'ManaSteal':
        return <i className="mdi mdi-water"></i>;
      case 'ManaStealMagic':
        return <i className="mdi mdi-magic"></i>;
      case 'EnergySteal':
        return <i className="mdi mdi-battery-charging"></i>;
      case 'Mana':
        return <i className="mdi mdi-water"></i>;
      case 'ManaRegen':
        return <i className="mdi mdi-water-pump"></i>;
      case 'CoolDownReducer':
        return <i className="mdi mdi-timer"></i>;
      case 'Energy':
        return <i className="mdi mdi-flash"></i>;
      case 'EnergyRegen':
        return <i className="mdi mdi-flash"></i>;
      case 'AttackDamage':
        return <i className="mdi mdi-sword"></i>;
      case 'Accuracy':
        return <i className="mdi mdi-target"></i>;
      case 'AttackSpeed':
        return <i className="mdi mdi-run-fast"></i>;
      case 'CriticalHitDamage':
        return <i className="mdi mdi-alert-circle-outline"></i>;
      case 'CriticalHitPosibility':
        return <i className="mdi mdi-alert"></i>;
      case 'MagicAttack':
        return <i className="mdi mdi-fire"></i>;
      case 'Armor':
        return <i className="mdi mdi-shield"></i>;
      case 'MagicResistance':
        return <i className="mdi mdi-shield"></i>;
      case 'TeamWork':
        return <i className="mdi mdi-account-group"></i>;
      case 'Dodge':
        return <i className="mdi mdi-run"></i>;
      case 'IsSilence':
        return <i className="mdi mdi-volume-off"></i>;
      default:
        return <i className="mdi mdi-help-circle-outline"></i>;
    }
  };

  return (
    <span className="improvement-type-icon">
      {getIcon(type)}
    </span>
  );
};

export default ImprovementTypeIcon;
