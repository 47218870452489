import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CharacterAttributeLevel.css'; // Ensure you have the correct path to your CSS file
import CardMenuButton from '../Planet/CardMenuButton';

import { useNavigate } from 'react-router-dom';

const CharacterAttributeLevels = () => {
    const [characterLevel, setCharacterLevel] = useState(null);

    const navigate = useNavigate(); 

    useEffect(() => {
        fetchCharacterLevels();
    }, []);

    const fetchCharacterLevels = async () => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                window.location.href = '/login';
                throw new Error('No token found');
              }

            const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/character/characterLevels', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            console.log("response", response);
            setCharacterLevel(response.data);
         } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error fetching :', error);

        }
      }
    };

    if (!characterLevel) {
        return <div>Loading...</div>;
    }

    const renderAttributeItem = (name, level, points, goal) => {
        const progress = (points / goal) * 100;
        return (
            <div className="attribute-item" key={name}>
                <div className="attribute-level">{level}</div>
                <div className="attribute-name">{name}</div>
                <div className="attribute-progress">
                    <div className="attribute-progress-bar" style={{ width: `${progress}%` }}></div>
                    <div className="attribute-progress-text">{points} / {goal}</div>
                </div>
            </div>
        );
    };

    return (
        <div className="containerCharacterStat">
             <div className="menu">
                <CardMenuButton title="Postava" onClick={() => navigate('/g/character')} />
        <CardMenuButton title="Inventár" onClick={() => navigate('/g/characterItems')} />
        <CardMenuButton title="Dielňa" onClick={() => navigate('/g/characterItemCraft')} />
      </div>
            <h1>Character Attribute Levels</h1>
            {renderAttributeItem("Strength", characterLevel.strengthLevel, characterLevel.strengthLevelPoints, characterLevel.strengthLevelPointsGoal)}
            {renderAttributeItem("Vitality", characterLevel.vitalityLevel, characterLevel.vitalityLevelPoints, characterLevel.vitalityLevelPointsGoal)}
            {renderAttributeItem("Intelligence", characterLevel.intelligenceLevel, characterLevel.intelligenceLevelPoints, characterLevel.intelligenceLevelPointsGoal)}
            {renderAttributeItem("Charisma", characterLevel.charismaLevel, characterLevel.charismaLevelPoints, characterLevel.charismaLevelPointsGoal)}
            {renderAttributeItem("Stamina", characterLevel.staminaLevel, characterLevel.staminaLevelPoints, characterLevel.staminaLevelPointsGoal)}
            {renderAttributeItem("Combat", characterLevel.combatLevel, characterLevel.combatLevelPoints, characterLevel.combatLevelPointsGoal)}

            <h2>Character Stats</h2>
            <div className="table-container">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Stat</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Life Steal</td>
                            <td>{characterLevel.lifeSteal}</td>
                        </tr>
                        <tr>
                            <td>Life Steal Magic</td>
                            <td>{characterLevel.lifeStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Mana Steal</td>
                            <td>{characterLevel.manaSteal}</td>
                        </tr>
                        <tr>
                            <td>Mana Steal Magic</td>
                            <td>{characterLevel.manaStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Energy Steal</td>
                            <td>{characterLevel.energySteal}</td>
                        </tr>
                        <tr>
                            <td>Energy Steal Magic</td>
                            <td>{characterLevel.energyStealMagic}</td>
                        </tr>
                        <tr>
                            <td>Cool Down Reducer</td>
                            <td>{characterLevel.coolDownReducer}</td>
                        </tr>
                        <tr>
                            <td>Attack Damage</td>
                            <td>{characterLevel.attackDamage}</td>
                        </tr>
                        <tr>
                            <td>Accuracy</td>
                            <td>{characterLevel.accuracy}</td>
                        </tr>
                        <tr>
                            <td>Attack Speed</td>
                            <td>{characterLevel.attackSpeed}</td>
                        </tr>
                        <tr>
                            <td>Critical Hit Damage</td>
                            <td>{characterLevel.criticalHitDamage}</td>
                        </tr>
                        <tr>
                            <td>Critical Hit Posibility</td>
                            <td>{characterLevel.criticalHitPosibility}</td>
                        </tr>
                        <tr>
                            <td>Magic Attack</td>
                            <td>{characterLevel.magicAttack}</td>
                        </tr>
                        <tr>
                            <td>Armor</td>
                            <td>{characterLevel.armor}</td>
                        </tr>
                        <tr>
                            <td>Magic Resistance</td>
                            <td>{characterLevel.magicResistance}</td>
                        </tr>
                        <tr>
                            <td>Team Work</td>
                            <td>{characterLevel.teamWork}</td>
                        </tr>
                        <tr>
                            <td>Capacity</td>
                            <td>{characterLevel.capacity}</td>
                        </tr>
                        <tr>
                            <td>Dodge</td>
                            <td>{characterLevel.dodge}</td>
                        </tr>
                        <tr>
                            <td>Exp</td>
                            <td>{characterLevel.exp}</td>
                        </tr>
                        <tr>
                            <td>Mine Gold</td>
                            <td>{characterLevel.mineGold}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CharacterAttributeLevels;
