import React, { useState } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';


const CombatScreen = () => {
  const { spotId } = useParams(); // Retrieve spotId and combatId from URL
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  const handleFightClick = async () => {
    try {


      // Retrieve the token from local storage or any other secure place where you store the token
      const token = localStorage.getItem('token');

      if (!token) {
        window.location.href = '/login';
        throw new Error('No token found');
      }
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/generateSignature/spot/${spotId}`, {}, { headers });
      const spotSignature = response.data;
      setMessage(`Spot Signature: ${spotSignature.signature}`);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error :', error);
      setError(error.message);
      }
    }
  };

  return (
    <div>
      <h1 onClick={handleFightClick}>FIGHT</h1>
      {message && <p>{message + error}</p>}
    </div>
  );
};

export default CombatScreen;