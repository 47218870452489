import React, { useState } from 'react';
import './VerificationModal.css';

const VerificationModal = ({ isOpen, onClose, spot, Key, onCreateVerificationRequest }) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  if (!isOpen) return null;

  const handleVideoUpload = async () => {
    if (videoFile) {
      setUploading(true);
      setUploadMessage('');
      try {
        await onCreateVerificationRequest(spot.id, Key, text, url, videoFile);
        setUploadMessage('Video uploaded successfully.');
      } catch (error) {
        setUploadMessage('Error uploading video.');
      } finally {
        setUploading(false);
      }
    } else {
      setUploadMessage('Please select a video file.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="activity-info">
            {spot.spotActivities.map((activity, index) => (
              <div key={index} className="activity-details">
                <div className="icon-circle">
                  <img src={`/activity/${activity.activityTemplate.iconName}`} alt="icon" />
                </div>
                <h4>{activity.activityTemplate.name} ({activity.activityLevelTemplate.goal})</h4>
              </div>
            ))}
          </div>
          <div className="spot-name">
            <h3>{spot.name}</h3>
          </div>
        </div>
        <div className="modal-body">
          {spot.spotActivities.map((activity, index) => (
            <div key={index}>
              <p className="description">{activity.activityTemplate.description}</p>
              <p className="level-note">{activity.activityLevelTemplate.levelNote}</p>
            </div>
          ))}
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter text"
            className="styled-input"
          />
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL"
            className="styled-input"
          />
          <input
            type="file"
            onChange={(e) => setVideoFile(e.target.files[0])}
            accept="video/*"
            className="styled-input"
          />
          <button onClick={handleVideoUpload} disabled={uploading} className="styled-button">
            {uploading ? 'Uploading...' : 'Upload Video'}
          </button>
          {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
        </div>
        <button onClick={onClose} className="styled-button close-button">Close</button>
      </div>
    </div>
  );
};

export default VerificationModal;
