// SpellActivateTypeIcon.js
import React from 'react';
import './SpellActivateTypeIcon.css';

const SpellActivateTypeIcon = ({ type }) => {
  const getIcon = (type) => {
    switch(type) {
      case 1:
        return <i className="mdi mdi-clock-outline"></i>;
      case 2:
        return <i className="mdi mdi-flash"></i>;
      case 3:
        return <i className="mdi mdi-layers-outline"></i>;
      case 4:
        return <i className="mdi mdi-battery-charging"></i>;
      default:
        return <i className="mdi mdi-help-circle-outline"></i>;
    }
  };

  return (
    <span className="spell-activate-type-icon">
      {getIcon(type)}
    </span>
  );
};

export default SpellActivateTypeIcon;