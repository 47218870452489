import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SpellLevelTemplateDetails = () => {
    const { spellTemplateId, spellLevelTemplateId } = useParams();
    const navigate = useNavigate();
    const [spellLevelTemplate, setSpellLevelTemplate] = useState({
        id: spellLevelTemplateId,
        spellTemplateId: spellTemplateId,
        levelNote: '',
        coolDown: 0,
        costHP: 0,
        costMana: 0,
        costEnergy: 0,
        hpPo: 0,
        hpPe: 0,
        hpRegenPo: 0,
        hpRegenPe: 0,
        lifeStealPo: 0,
        lifeStealPe: 0,
        lifeStealMagicPo: 0,
        lifeStealMagicPe: 0,
        manaStealPo: 0,
        manaStealPe: 0,
        manaStealMagicPo: 0,
        manaStealMagicPe: 0,
        energyStealPo: 0,
        energyStealPe: 0,
        manaPo: 0,
        manaPe: 0,
        manaRegenPo: 0,
        manaRegenPe: 0,
        coolDownReducerPo: 0,
        coolDownReducerPe: 0,
        energyPo: 0,
        energyPe: 0,
        energyRegenPo: 0,
        energyRegenPe: 0,
        attackDamagePo: 0,
        attackDamagePe: 0,
        accuracyPo: 0,
        accuracyPe: 0,
        attackSpeedPo: 0,
        attackSpeedPe: 0,
        criticalHitDamagePo: 0,
        criticalHitDamagePe: 0,
        criticalHitPosibilityPo: 0,
        criticalHitPosibilityPe: 0,
        magicAttackPo: 0,
        magicAttackPe: 0,
        armorPo: 0,
        armorPe: 0,
        magicResistancePo: 0,
        magicResistancePe: 0,
        teamWorkPo: 0,
        teamWorkPe: 0,
        dodgePo: 0,
        dodgePe: 0,
        isSilence: false,
    });

    useEffect(() => {
        fetchSpellLevelTemplate();
    }, [spellTemplateId, spellLevelTemplateId]);

    const fetchSpellLevelTemplate = async () => {
        try {
            const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellLevelTemplates/${spellLevelTemplateId}`);
            setSpellLevelTemplate(response.data);
        } catch (error) {
            console.error('Error fetching spell level template:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSpellLevelTemplate({ ...spellLevelTemplate, [name]: value });
    };

    const handleSave = async () => {
        try {
            const payload = { ...spellLevelTemplate, id: spellLevelTemplateId, spellTemplateId: spellTemplateId };
            console.log('Payload:', payload);
            await axios.put(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellLevelTemplates/${spellLevelTemplateId}`, payload);
            navigate(`/spellTemplate/${spellTemplateId}`);
        } catch (error) {
            console.error('Error saving spell level template:', error);
        }
    };

    const handleCancel = () => {
        navigate(`/spellTemplate/${spellTemplateId}`);
    };

    if (!spellLevelTemplate) {
        return <div>Loading...</div>;
    }

    const properties = [
        'levelNote', 'coolDown', 'costHP', 'costMana', 'costEnergy', 'hpPo', 'hpPe', 'hpRegenPo', 'hpRegenPe',
        'lifeStealPo', 'lifeStealPe', 'lifeStealMagicPo', 'lifeStealMagicPe', 'manaStealPo', 'manaStealPe',
        'manaStealMagicPo', 'manaStealMagicPe', 'energyStealPo', 'energyStealPe', 'manaPo', 'manaPe',
        'manaRegenPo', 'manaRegenPe', 'coolDownReducerPo', 'coolDownReducerPe', 'energyPo', 'energyPe',
        'energyRegenPo', 'energyRegenPe', 'attackDamagePo', 'attackDamagePe', 'accuracyPo', 'accuracyPe',
        'attackSpeedPo', 'attackSpeedPe', 'criticalHitDamagePo', 'criticalHitDamagePe', 'criticalHitPosibilityPo',
        'criticalHitPosibilityPe', 'magicAttackPo', 'magicAttackPe', 'armorPo', 'armorPe', 'magicResistancePo',
        'magicResistancePe', 'teamWorkPo', 'teamWorkPe', 'dodgePo', 'dodgePe', 'isSilence'
    ];

    return (
        <div className="container">
            <h1>Spell Level Template Details</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Spell Level Template</h5>
                    <table className="table mt-3">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {properties.map((property) => (
                                <tr key={property}>
                                    <td>{property}</td>
                                    <td>
                                        <input
                                            type="text"
                                            name={property}
                                            value={spellLevelTemplate[property] || ''}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="btn btn-primary" onClick={handleSave}>Save</button>
                    <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default SpellLevelTemplateDetails;
