import React, { useEffect, useState } from 'react';
import './CombatKeys.css';
import axios from 'axios';
import CardMenuButton from '../Planet/CardMenuButton';
import { useNavigate } from 'react-router-dom';

const CombatKeys = () => {
  const [combatKeys, setCombatKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCombatKeys = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/combat/combatKye', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        setCombatKeys(response.data);
       } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
        console.error('Error ', error);
        setError(error.message);
        }
   

      } finally {
        setLoading(false);
      }
    };

    fetchCombatKeys();
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Reset the copied state after 2 seconds
    } catch (err) {
      setCopySuccess('Failed to copy!');
      setTimeout(() => setCopySuccess(''), 2000); // Reset the copied state after 2 seconds
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className='containerCombatKeys'>
      <div className="menu">
        <CardMenuButton title="Postava" onClick={() => navigate('/g/character')} />
        <CardMenuButton title="Inventár" onClick={() => navigate('/g/characterItems')} />
        <CardMenuButton title="Dielňa" onClick={() => navigate('/g/characterItemCraft')} />
      </div>
      <h2>Combat Keys</h2>
      <table className="combat-keys-table">
        <thead>
          <tr>
            <th>Spot Name</th>
            <th>Copy Key</th>
          </tr>
        </thead>
        <tbody>
          {combatKeys.map((key, index) => (
            <tr key={index}>
              <td>{key?.generatedSignature?.spot?.name}</td>
              <td>
                <button onClick={() => copyToClipBoard(key.generatedSignature?.signature)}>Copy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {copySuccess && <p className="copy-notification">{copySuccess}</p>}
    </div>
  );
};

export default CombatKeys;
