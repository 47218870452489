import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SpellTemplateDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [spellTemplate, setSpellTemplate] = useState(null);
    const [spellLevels, setSpellLevels] = useState([]);
    const [editing, setEditing] = useState({});

    useEffect(() => {
        fetchSpellTemplate();
        fetchSpellLevels();
    }, [id]);

    const fetchSpellTemplate = async () => {
        try {
            const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spelltemplates/${id}`);
            setSpellTemplate(response.data);
        } catch (error) {
            console.error('Error fetching spell template:', error);
        }
    };

    const fetchSpellLevels = async () => {
        try {
            const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellLevelTemplatesFromSpellTemplateId/${id}`);
            console.log(response.data);
            setSpellLevels(response.data.$values || []);
        } catch (error) {
            console.error('Error fetching spell levels:', error);
            setSpellLevels([]); // Ensure spellLevels is always an array
        }
    };

    const handleAddLevel = async () => {
        try {
            await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellTemplate/${id}/newDefaultSpellLevelTemplate`);
            fetchSpellLevels();
        } catch (error) {
            console.error('Error adding new spell level template:', error);
        }
    };


    const handleChange = (e, levelId) => {
        const { name, value } = e.target;
        const parsedValue = isNaN(parseInt(value)) ? value : parseInt(value);
        setSpellLevels((prevLevels) =>
            prevLevels.map((level) =>
                level.id === levelId ? { ...level, [name]: parsedValue } : level
            )
        );
    };

    const handleSave = async (levelId) => {
        const level = spellLevels.find((l) => l.id === levelId);
        try {
            const payload = { ...level, id: levelId, spellTemplateId: parseInt(id), spellTemplate: spellTemplate };
    
            // Remove extraneous properties like $id if they exist
            const { $id, ...cleanPayload } = payload;
    console.log($id);
            console.log('Clean Payload:', cleanPayload);
            await axios.put(`https://dakujemorg-002-site1.ftempurl.com/api/spell/spellLevelTemplates/${levelId}`, cleanPayload);
            setEditing((prevEditing) => ({ ...prevEditing, [levelId]: false }));
        } catch (error) {
            console.error('Error saving spell level template:', error);
        }
    };

    const handleEdit = (levelId) => {
        setEditing((prevEditing) => ({ ...prevEditing, [levelId]: true }));
    };

    const handleCancel = (levelId) => {
        setEditing((prevEditing) => ({ ...prevEditing, [levelId]: false }));
    };

    const handleLevelClick = (levelId) => {
        navigate(`/spellTemplate/${id}/SpellLevelTemplate/${levelId}`);
    };

    if (!spellTemplate) {
        return <div>Loading...</div>;
    }

    const properties = [
        'levelNote', 'coolDown', 'costHP', 'costMana', 'costEnergy', 'hpPo', 'hpPe', 'hpRegenPo', 'hpRegenPe',
        'lifeStealPo', 'lifeStealPe', 'lifeStealMagicPo', 'lifeStealMagicPe', 'manaStealPo', 'manaStealPe',
        'manaStealMagicPo', 'manaStealMagicPe', 'energyStealPo', 'energyStealPe', 'manaPo', 'manaPe',
        'manaRegenPo', 'manaRegenPe', 'coolDownReducerPo', 'coolDownReducerPe', 'energyPo', 'energyPe',
        'energyRegenPo', 'energyRegenPe', 'attackDamagePo', 'attackDamagePe', 'accuracyPo', 'accuracyPe',
        'attackSpeedPo', 'attackSpeedPe', 'criticalHitDamagePo', 'criticalHitDamagePe', 'criticalHitPosibilityPo',
        'criticalHitPosibilityPe', 'magicAttackPo', 'magicAttackPe', 'armorPo', 'armorPe', 'magicResistancePo',
        'magicResistancePe', 'teamWorkPo', 'teamWorkPe', 'dodgePo', 'dodgePe', 'isSilence'
    ];

    return (
        <div className="container">
            <h1>Spell Template Details</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">{spellTemplate.name}</h5>
                    <p className="card-text">{spellTemplate.description}</p>
                    <p className="card-text"><strong>Spell Type:</strong> {spellTemplate.spellType?.name}</p>
                </div>
            </div>
            <h2>Spell Level Templates</h2>
            <button className="btn btn-primary mb-3" onClick={handleAddLevel}>Add Spell Level Template</button>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Property</th>
                        {spellLevels.map((level) => (
                            <th key={level.id}>
                                Level {level.id}
                                <button className="btn btn-link" onClick={() => handleLevelClick(level.id)}>View</button>
                                {!editing[level.id] && (
                                    <button className="btn btn-link" onClick={() => handleEdit(level.id)}>Edit</button>
                                )}
                                {editing[level.id] && (
                                    <>
                                        <button className="btn btn-link" onClick={() => handleSave(level.id)}>Save</button>
                                        <button className="btn btn-link" onClick={() => handleCancel(level.id)}>Cancel</button>
                                    </>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {properties.map((property) => (
                        <tr key={property}>
                            <td>{property}</td>
                            {spellLevels.map((level) => (
                                <td key={level.id}>
                                    {editing[level.id] ? (
                                        <input
                                            type="text"
                                            name={property}
                                            value={level[property] || ''}
                                            onChange={(e) => handleChange(e, level.id)}
                                            className="form-control"
                                        />
                                    ) : (
                                        level[property]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SpellTemplateDetails;
