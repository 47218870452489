import React, { useState } from 'react';
import './SpellStackVerificationModal.css';

const SpellStackVerificationModal = ({ isOpen, onClose, spell, onCreateSpellVerificationRequest, spellKey }) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  if (!isOpen) return null;

  const handleVideoUpload = async () => {
    if (videoFile) {
      setUploading(true);
      setUploadMessage('');
      try {
        await onCreateSpellVerificationRequest(spell.spellId, spellKey, text, url, videoFile);
        setUploadMessage('Video uploaded successfully.');
      } catch (error) {
        setUploadMessage('Error uploading video.');
      } finally {
        setUploading(false);
      }
    } else {
      setUploadMessage('Please select a video file.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>{spell.name}</h3>
        <p><strong>Generated Key:</strong> {spellKey}</p>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text"
        />
        <input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter URL"
        />
        <input
          type="file"
          onChange={(e) => setVideoFile(e.target.files[0])}
          accept="video/*"
        />
        <button onClick={handleVideoUpload} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Video'}
        </button>
        {uploadMessage && <p>{uploadMessage}</p>}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SpellStackVerificationModal;
