import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MapComponent.css';
import mapboxgl from 'mapbox-gl';
import { FaFilter } from 'react-icons/fa';
import CardMenuButton from '../Planet/CardMenuButton';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from 'react-router-dom';
import VerificationModal from './VerificationModal'; // Import the Modal component
import SpotPopup from './SpotPopup';



const MapSpot = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [spots, setSpots] = useState([]);
  const [filteredSpots, setFilteredSpots] = useState([]);
  const [filters, setFilters] = useState({
    gameAttributes: [],
    level: '',
    zone: '',
  });
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [selectedSpot, setSelectedSpot] = useState(null);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [Key, setKey] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false); // State to toggle filter popup

  const navigate = useNavigate();

  const gameAttributes = [
    { id: 1, status: 1, name: 'Vitalita' },
    { id: 2, status: 1, name: 'Sila' },
    { id: 3, status: 1, name: 'Inteligencia' },
    { id: 4, status: 1, name: 'Výdrž' },
    { id: 5, status: 1, name: 'Súboj' },
    { id: 6, status: 1, name: 'Charizma' }
  ];

  useEffect(() => {
    // Fetch spots from backend
    const fetchSpots = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in local storage.');
          setVerificationMessage('No token found. Please log in.');
          window.location.href = '/login';
          return;
        }

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/activity/map', { headers });
        console.log("response.data", response.data);
        setSpots(response.data.$values || response.data);
        setFilteredSpots(response.data.$values || response.data);
        initializeMap(response.data.$values || response.data);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          console.error('Error  :', error);
        }
      }
    };

    fetchSpots();
  }, []);

  const initializeMap = (spots) => {
    if (!mapContainerRef.current) return;

    // Ensure the map container is empty
    mapContainerRef.current.innerHTML = '';

    mapboxgl.accessToken = 'pk.eyJ1IjoibWFyb3NraXJhIiwiYSI6ImNsd25zbm5jMjIweW4yaXBmdWd6Z2o1b3cifQ.FGts0gETD3can90rbB6MnA';
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [17.1482, 48.15972], // Initial map center [lng, lat]
      zoom: 10
    });

    spots.forEach(spot => {

      const markerElement = document.createElement('div');
      markerElement.className = 'custom-marker';
      const iconElement = document.createElement('img');
      iconElement.src = `/activity/` + spot.spotActivities[0]?.activityTemplate?.iconName;
      iconElement.className = 'marker-icon';
      markerElement.style.backgroundColor = spot.gameAttribute?.color;
      markerElement.style.color = "white";
      markerElement.appendChild(iconElement);

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([spot.lon, spot.lat])
        .addTo(map);

      marker.getElement().addEventListener('click', () => {
        setSelectedSpot(spot);
        setIsFilterPopupOpen(true);
      });
    });

    mapRef.current = map; // Store the map instance
  };

  const updateMapMarkers = (spots) => {
    if (!mapRef.current) return;
    // Clear existing markers
    const markers = document.querySelectorAll('.mapboxgl-marker');
    markers.forEach(marker => marker.remove());

    // Add new markers
    spots.forEach(spot => {
      console.log("chech update ", `<img src="/activity/` +  spot.spotActivities[0]?.activityTemplate?.iconName + `]" alt="activity icon" class="marker-icon"/>`);
      // Create a custom marker element
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundColor = spot.gameAttribute?.color;
      el.innerHTML = `<img src="/activity/` +  spot.spotActivities[0]?.activityTemplate?.iconName + `" alt="activity icon" class="marker-icon"/>`;

      const marker = new mapboxgl.Marker(el)
        .setLngLat([spot.lon, spot.lat])
        .addTo(mapRef.current);

      marker.getElement().addEventListener('click', () => {
        setSelectedSpot(spot);
        setIsFilterPopupOpen(true);
      });
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return { ...prevFilters, gameAttributes: [...prevFilters.gameAttributes, name] };
      } else {
        return { ...prevFilters, gameAttributes: prevFilters.gameAttributes.filter(attr => attr !== name) };
      }
    });
  };

  const applyFilters = () => {
    let filtered = spots;
    console.log("filter spots" , spots);
    if (filters.gameAttributes.length > 0) {
      filtered = filtered.filter(spot => filters.gameAttributes.includes(spot.gameAttribute.name));
    }
    if (filters.level) {
      filtered = filtered.filter(spot => spot.level === parseInt(filters.level));
    }
    if (filters.zone) {
      filtered = filtered.filter(spot => spot.zone.name.includes(filters.zone));
    }
    setFilteredSpots(filtered);
    updateMapMarkers(filtered);
    setIsFilterVisible(false); // Close the filter popup after applying filters
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon1 - lon2) * Math.PI / 180;
    const a =
      0.5 - Math.cos(dLat) / 2 +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      (1 - Math.cos(dLon)) / 2;

    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const verifyProximity = async () => {
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const distance = calculateDistance(latitude, longitude, selectedSpot.lat, selectedSpot.lon) * 1000; // Convert to meters
      console.log("distance", distance);
      if (distance <= selectedSpot.radius) {
        setVerificationMessage('Yes');
        startCombat();
      } else {
        setVerificationMessage('No');
      }
    } catch (error) {
      console.error('Error getting current position:', error);
      setVerificationMessage('Unable to determine location.');
    }
  };

  const verifyKey = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage.');
        setVerificationMessage('No token found. Please log in.');
        window.location.href = '/login';
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      console.log("Token found: ", token);
      console.log("Request URL: ", `https://dakujemorg-002-site1.ftempurl.com/api/activity/SpotCheck/${selectedSpot.id}/${Key}`);

      const response = await axios.get(`https://dakujemorg-002-site1.ftempurl.com/api/activity/SpotCheck/${selectedSpot.id}/${Key}`, { headers });
      console.log("Response: ", response);

      const spotSignature = response.data;
      console.log("spotSignature: ", spotSignature);

      if (spotSignature) {
        console.log("Opening modal...");
        setIsModalOpen(true);
      } else {
        setVerificationMessage('Invalid spot key.');
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error  spells:', error);
        setVerificationMessage(error.message);
      }
    }
  };

  const startCombat = async () => {
    try {
      // Retrieve the token from local storage or any other secure place where you store the token
      const token = localStorage.getItem('token');

      // Set up the headers for the request
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/startCombat/${selectedSpot.id}`, {}, { headers });
      const combat = response.data;
      navigate(`/g/combat/${selectedSpot.id}/${combat.id}`);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching spells:', error);
      }
    }
  };

  const createVerificationRequest = async (spotId, Key, text, url, videoFile) => {
    try {
      console.log("Creating verification request...");
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage.');
        setVerificationMessage('No token found. Please log in.');
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const body = {
        spotId,
        Key,  // Include Key in the request payload
        text,
        url
      };

      console.log("Request payload:", body);  // Log the request payload

      const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createSpotVerification', body, { headers });
      const verificationRequest = response.data;
      console.log('Verification request created: ', verificationRequest);

      if (videoFile) {
        await handleVideoUpload(spotId, verificationRequest.id, videoFile);
      } else {
        setVerificationMessage('Verification request created without video.');
      }
    } catch (error) {
      console.error('Error creating verification request:', error);

      // Log detailed error information
      if (error.response && error.response.data && error.response.data.errors) {
        console.error('Validation errors:', error.response.data.errors);
      }

      setVerificationMessage('Error creating verification request.');
    }
  };

  const handleVideoUpload = async (spotId, verificationRequestId, videoFile) => {
    try {
      console.log("Uploading video...");
      const formData = new FormData();
      formData.append('videoFile', videoFile);

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage.');
        setVerificationMessage('No token found. Please log in.');
        return;
      }

      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/UploadVideo/${spotId}/${verificationRequestId}`, formData, { headers });
      console.log("Video upload response: ", response);
      setVerificationMessage('Video uploaded successfully.');
    } catch (error) {
      console.error('Error uploading video:', error);
      setVerificationMessage('Error uploading video.');
    }
  };

  const currentPosition = {
    lat: 48.15972, // Replace with actual current latitude
    lon: 17.1482   // Replace with actual current longitude
  };

  const openFilterPopup = (spot) => {
    setSelectedSpot(spot);
    setIsFilterPopupOpen(true);
  };

  const toggleFilterPopup = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const [isGameAttributesVisible, setIsGameAttributesVisible] = useState(false);

  return (
    <div className="containerMapSpots">
      <div className="menu">
        <CardMenuButton title="Spells" onClick={() => console.log('Character Spells')} />
        <CardMenuButton title="Learn" onClick={() => console.log('Spells to Learn')} />
        <CardMenuButton title="Stack" onClick={() => console.log('Stack Spells')} />
      </div>
      <FaFilter className="filter-icon" onClick={toggleFilterPopup} />
      {isFilterVisible && (
        <div className="filter-popup">
          <h3>Filter Spots</h3>
          <div>
            <button onClick={() => setIsGameAttributesVisible(!isGameAttributesVisible)}>
              Game Attributes
            </button>
            {isGameAttributesVisible && (
  <div className="checkbox-list">
    {gameAttributes.map(attr => (
      <label key={attr.id}>
        <input
          type="checkbox"
          name={attr.name}
          checked={filters.gameAttributes.includes(attr.name)}
          onChange={handleCheckboxChange}
        />
        {attr.name}
      </label>
    ))}
  </div>
)}
</div>
<label>
  Level:
  <input type="text" name="level" value={filters.level} onChange={handleFilterChange} />
</label>
<label>
  Zone:
  <input type="text" name="zone" value={filters.zone} onChange={handleFilterChange} />
</label>
<button onClick={applyFilters}>Apply Filters</button>
<button onClick={toggleFilterPopup}>Close</button>
</div>
)}

<div ref={mapContainerRef} className="map-container" />
<SpotPopup
    isOpen={isFilterPopupOpen} // Pass the state to control visibility
    spot={selectedSpot}
    verificationMessage={verificationMessage}
    Key={Key}
    setKey={setKey}
    verifyProximity={verifyProximity}
    verifyKey={verifyKey}
    onClose={() => setIsFilterPopupOpen(false)}
/>
<VerificationModal
  isOpen={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  spot={selectedSpot}
  Key={Key}  // Pass Key to the VerificationModal component
  onCreateVerificationRequest={createVerificationRequest}
/>
<h2>Spots within 2km</h2>
<table className="tableMapSpot">
  <thead>
    <tr>
      <th>Name</th>
      <th>Zone</th>
      <th>Distance (km)</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredSpots.filter(spot => calculateDistance(currentPosition.lat, currentPosition.lon, spot.lat, spot.lon) <= 2).map((spot) => (
      <tr key={spot.id}>
        <td>{spot.name}</td>
        <td>{spot.zone?.name}</td>
        <td>{calculateDistance(currentPosition.lat, currentPosition.lon, spot.lat, spot.lon).toFixed(2)}</td>
        <td><button onClick={() => openFilterPopup(spot)}>Details</button></td>
      </tr>
    ))}
  </tbody>
</table>
</div>
);
};

export default MapSpot;