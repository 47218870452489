import React, { useState } from 'react';
import './login.css'; // Make sure this is the correct path

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://dakujemorg-002-site1.ftempurl.com/api/Account/Login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Email: email,
          Password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data.token;
        const refreshToken = data.refreshToken;

        // Store the tokens in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);

        console.log('Login successful:', data);
        window.location.href = '/g/market';
      } else {
        console.error('Login failed:', data.message);
        setError(data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Network error:', error);
      setError('Network error. Please try again later.' + error);
    }
  };

  return (
    <div className="backgroundLogin">
      <div className="login-screen-container">
        <form className="login-form" onSubmit={handleSubmit} style={{ borderRadius: '15px' }}>
          <h2 style={{ textAlign: 'center', color: '#FFFFFF', margin: '0.5rem 0' }}>Login</h2>
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <label htmlFor="email" style={{ color: '#FFFFFF' }}>Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password" style={{ color: '#FFFFFF' }}>Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">Log In</button>
          <p className="register-prompt">
            Dont have an account? <a href="/register" className="register-link">Register</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
