import React, { useState } from 'react';
import axios from 'axios';
import SpellActivateTypeIcon from './SpellActivateTypeIcon';
import SpellEffectTypeIcon from './SpellEffectTypeIcon';
import SpellTargetTypeIcon from './SpellTargetTypeIcon';
import ImprovementTypeIcon from './SpellImprovementTypeIcon';
import SpellStackVerificationModal from './SpellStackVerificationModal';
import GenerateSignatureModal from './GenerateSignatureModal';
import './SpellCard.css';

const SpellCard = ({ spell, showCount = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [generatedSignature, setGeneratedSignature] = useState(null);
  const [error, setError] = useState(null);

  const getBorderColor = (gameAttributeId) => {
    switch (gameAttributeId) {
      case 1:
        return 'border-color-red';
      case 2:
        return 'border-color-green';
      case 3:
        return 'border-color-blue';
      case 4:
        return 'border-color-yellow';
      case 5:
        return 'border-color-purple';
      case 6:
        return 'border-color-orange';
      default:
        return 'border-color-default';
    }
  };

  const levelTemplate = spell.spellLevelTemplates && spell.spellLevelTemplates[0];

  const improvements = levelTemplate ? [
    { type: 'HpRegen', value: levelTemplate.hpRegenPo },
    { type: 'LifeSteal', value: levelTemplate.lifeStealPo },
    { type: 'LifeStealMagic', value: levelTemplate.lifeStealMagicPo },
    { type: 'ManaSteal', value: levelTemplate.manaStealPo },
    { type: 'ManaStealMagic', value: levelTemplate.manaStealMagicPo },
    { type: 'EnergySteal', value: levelTemplate.energyStealPo },
    { type: 'Mana', value: levelTemplate.manaPo },
    { type: 'ManaRegen', value: levelTemplate.manaRegenPo },
    { type: 'CoolDownReducer', value: levelTemplate.coolDownReducerPo },
    { type: 'Energy', value: levelTemplate.energyPo },
    { type: 'EnergyRegen', value: levelTemplate.energyRegenPo },
    { type: 'AttackDamage', value: levelTemplate.attackDamagePo },
    { type: 'Accuracy', value: levelTemplate.accuracyPo },
    { type: 'AttackSpeed', value: levelTemplate.attackSpeedPo },
    { type: 'CriticalHitDamage', value: levelTemplate.criticalHitDamagePo },
    { type: 'CriticalHitPosibility', value: levelTemplate.criticalHitPosibilityPo },
    { type: 'MagicAttack', value: levelTemplate.magicAttackPo },
    { type: 'Armor', value: levelTemplate.armorPo },
    { type: 'MagicResistance', value: levelTemplate.magicResistancePo },
    { type: 'TeamWork', value: levelTemplate.teamWorkPo },
    { type: 'Dodge', value: levelTemplate.dodgePo },
    { type: 'IsSilence', value: levelTemplate.isSilence }
  ].filter(improvement => improvement.value !== undefined && improvement.value !== 0 ) : [];

  const handleGenerateSignature = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/generateSignature/spell/${spell.spellId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      setGeneratedSignature(response.data.signature);
      setIsSignatureModalOpen(false);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error generating signature:', error.response ? error.response.data : error.message);
    }
  };

  const handleCreateSpellVerificationRequest = async (spellId, spellKey, text, url, videoFile) => {
    try {
      const model = {
        SpellId: spellId,
        Key: spellKey,
        Text: text,
        Url: url,
      };
console.log("model", model);
      const token = localStorage.getItem('token');
      const response = await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/activity/createSpellVerification', model, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const verificationRequest = response.data;

      if (verificationRequest && videoFile) {
        const formData = new FormData();
        formData.append('videoFile', videoFile);

        await axios.post(`https://dakujemorg-002-site1.ftempurl.com/api/activity/UploadVideo/00000000-0000-0000-0000-000000000000/${verificationRequest.id}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
      }
console.log(error);
      setIsModalOpen(false);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      } else {
      console.error('Error fetching spells:', error);
      setError(error.message);
      }
    }
  };

  return (
    <div className={`spell-card ${getBorderColor(spell.gameAttributeId)}`}>
      <div className="spell-card-header">
        <div className="spell-name">
          {spell.name}
          {showCount && spell.count ? (
            <span
              className="spell-count"
              onClick={() => setIsSignatureModalOpen(true)}
              style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '5px' }}
            >
              ({spell.count})
            </span>
          ) : null}
        </div>
        <div className="spell-icons">
          <SpellActivateTypeIcon type={spell.spellActivateTypeId} />
          <SpellEffectTypeIcon type={spell.spellEffectTypeId} />
          <SpellTargetTypeIcon type={spell.spellTargetTypeId} />
        </div>
      </div>
      <div className="spell-description">
        {spell.description}
      </div>
      <div className="spell-footer">
        <div className="spell-improvements">
          {improvements.map((improvement, index) => (
            <div key={index} className="spell-improvement">
              <ImprovementTypeIcon type={improvement.type} />
              <span className="improvement-value">{improvement.value}</span>
            </div>
          ))}
        </div>
        <div className="spell-level">
          {levelTemplate ? levelTemplate.level : 1}
        </div>
      </div>
      {isSignatureModalOpen && (
        <GenerateSignatureModal
          isOpen={isSignatureModalOpen}
          onClose={() => setIsSignatureModalOpen(false)}
          onConfirm={handleGenerateSignature}
        />
      )}
      {isModalOpen && (
        <SpellStackVerificationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          spell={spell}
          onCreateSpellVerificationRequest={handleCreateSpellVerificationRequest}
          spellKey={generatedSignature}
        />
      )}
    </div>
  );
};

export default SpellCard;
