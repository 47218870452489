import React from 'react';
/*import './GenerateSignatureModal.css';*/

const GenerateSignatureModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Generate Signature</h3>
        <p>Do you want to generate a signature for this spell? You can only do this once a day.</p>
        <div className="modal-buttons">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default GenerateSignatureModal;