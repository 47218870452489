// EffectTypeIcon.js
import React from 'react';
import './SpellEffectTypeIcon.css';

const EffectTypeIcon = ({ code }) => {
  const getIcon = (code) => {
    switch(code) {
      case 1:
        return <i className="mdi mdi-chart-line"></i>; // Stats icon
      case 2:
        return <i className="mdi mdi-volume-off"></i>; // Silent icon
      case 3:
        return <i className="mdi mdi-hand"></i>; // Stun icon
      case 4:
        return <i className="mdi mdi-flash-off"></i>; // Debuff icon
      case 5:
        return <i className="mdi mdi-reflect-horizontal"></i>; // Mirror icon
      case 6:
        return <i className="mdi mdi-shield-check"></i>; // Invincibility icon
      case 7:
        return <i className="mdi mdi-shield-cross"></i>; // Physical Invincibility icon
      case 8:
        return <i className="mdi mdi-shield-outline"></i>; // Magical Invincibility icon
      default:
        return <i className="mdi mdi-help-circle-outline"></i>; // Default icon
    }
  };

  return (
    <span className="effect-type-icon">
      {getIcon(code)}
    </span>
  );
};

export default EffectTypeIcon;