import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './CharacterSpell.css'; // Ensure you have the correct path to your CSS file

const CharacterSpells = () => {
  const [spells, setSpells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchSpells = async () => {
      try {
        console.log("error", error);
        const token = localStorage.getItem('token');

        if (!token) {
          window.location.href = '/login';
          throw new Error('No token found');
        }

        const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/spell/CharacterSpells', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });

        setSpells(response.data);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
          window.location.href = '/login';
        } else {
          setError('Error fetching spells');
          setLoading(false);
        }
      }
    };

    fetchSpells();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="containerCharacterSpell">

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Spell Name</th>
            <th>Level</th>
            <th>Activation Type</th>
          </tr>
        </thead>
        <tbody>
          {spells.map(spell => (
            <tr key={spell.id}>
              <td>{spell.spellTemplate?.name}</td>
              <td>{spell.spellLevelTemplate?.level}</td>
              <td>{spell.spellTemplate?.spellActivateType?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CharacterSpells;
