// src/components/SpellTypes.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';

const SpellTypes = () => {
  const [spellTypes, setSpellTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newSpellType, setNewSpellType] = useState({ name: '', description: '', code: '' });

  useEffect(() => {
    fetchSpellTypes();
  }, []);

  const fetchSpellTypes = async () => {
    try {
      const response = await axios.get('https://dakujemorg-002-site1.ftempurl.com/api/spell/SpellTypes');
      setSpellTypes(response.data);
    } catch (error) {
      console.error('Error fetching spell types:', error);
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSpellType({ ...newSpellType, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://dakujemorg-002-site1.ftempurl.com/api/spell/SpellTypes', newSpellType);
      fetchSpellTypes();
      handleClose();
    } catch (error) {
      console.error('Error adding spell type:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Spell Types</h2>
      <Button variant="primary" onClick={handleShow}>Add Spell Type</Button>
      <ul className="list-group mt-3">
        {spellTypes.map(spellType => (
          <li key={spellType.id} className="list-group-item">
            {spellType.name} - {spellType.description}
          </li>
        ))}
      </ul>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Spell Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newSpellType.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={newSpellType.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                name="code"
                value={newSpellType.code}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpellTypes;
